import DropDownMenu_LinesMenuButtonSkinComponent from '@wix/thunderbolt-elements/src/components/DropDownMenu/viewer/skinComps/LinesMenuButton/LinesMenuButtonSkin.skin';


const DropDownMenu_LinesMenuButtonSkin = {
  component: DropDownMenu_LinesMenuButtonSkinComponent
};


export const components = {
  ['DropDownMenu_LinesMenuButtonSkin']: DropDownMenu_LinesMenuButtonSkin
};


// temporary export
export const version = "1.0.0"
